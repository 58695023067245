<template>
  <v-card class="db-card">
    <v-card-title class="bg-border-lighter d-flex justify-space-between align-center db-card__title mb-4">
      <h4>
        <router-link :to="url" v-if="url">
          {{ title }}
        </router-link>
        <span v-else>{{ title }}</span>
      </h4>
        <v-btn icon v-if="add" :to="add" small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-card-title>
    <v-card-text class="db-card__content" v-if="$scopedSlots.default">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    url: {
      type: [String, Object]
    },
    add: {
      type: [String, Object]
    }
  }
}
</script>

<style lang="scss" scoped>
.db-card {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.db-card__title {
  position: sticky;
  top: 0;
  // background: #fff;
  z-index: 1;
}

.db-card__content {
  flex: 1 1 auto
}
</style>
