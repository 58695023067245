<template>
  <widget-card-base
    title="Журнал"
    :url="{ name: 'BlogPosts' }"
    :add="{ name: 'BlogPost', params: { id: 'new' } }"
  >
    <widget-typeahead
      :search="search"
      placeholder="Название статьи"

    >
      <template #item="{ item }">
        <router-link :to="{ name: 'BlogPost', params: { id: item.uuid } }" >
          {{ item.title }}
        </router-link>
      </template>
    </widget-typeahead>
  </widget-card-base>
</template>

<script>
import WidgetCardBase from '@/components/Widget/WidgetCardBase.vue'
import WidgetTypeahead from '@/components/Widget/WidgetTypeahead.vue'
import postsService from '@/services/posts'

const LIMIT = 5

export default {
  components: {
    WidgetCardBase,
    WidgetTypeahead
  },
  methods: {
    async search (search) {
      const { items } = await postsService.getAll({ limit: LIMIT, search })
      return items
    }
  }
}
</script>
