<template>
  <v-list class="widget-items-list">
    <v-list-item class="widget-items-list__item" v-for="item, idx in items" :key="item[keyField]">
      <slot v-bind="{ item, idx }" />
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    keyField: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-items-list__item {
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.25em;
  min-height: 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
</style>
