<template>
  <widget-card-base
    title="Страницы"
    :url="{ name: 'Pages' }"
    :add="{ name: 'Page', params: { id: 'new' } }"
  >
    <div class="text-center mt-4" v-if="req.pending">
      <v-progress-circular indeterminate />
    </div>
    <v-alert v-else-if="req.error" type="error">{{ req.error.message }}</v-alert>
    <widget-items-list v-else-if="req.data && req.data.length" :items="req.data" key-field="id" v-slot="{ item }">
      <router-link :to="{ name: 'Page', params: { id: item.id } }">
        {{ item.title || item.uri }}
      </router-link>
    </widget-items-list>
  </widget-card-base>
</template>

<script>
import pagesService from '@/services/pages'
import WidgetCardBase from '@/components/Widget/WidgetCardBase.vue'
import { createRequest } from '@/utils/requestsFactory'
import WidgetItemsList from '@/components/Widget/WidgetItemsList.vue'

export default {
  components: {
    WidgetCardBase,
    WidgetItemsList
  },
  data () {
    return {
      req: createRequest(async () => {
        const { items } = await pagesService.getAll()
        return items
      })
    }
  },
  mounted () {
    return this.req.run()
  }
}
</script>
