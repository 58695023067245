<template>
  <widget-finder
    :search="search"
    :query="query"
    v-slot="scope"
  >
    <div class="typeahead">
      <v-text-field
        v-model="query"
        prepend-inner-icon="mdi-magnify"
        hide-details
        class="mb-4 typeahead__input"
        v-bind="$attrs"
      />

      <div
        v-if="scope.pending"
        class="typeahead__pending typeahead__screen"
      >
        <slot name="pending" v-bind="scope">
          <v-progress-circular indeterminate />
        </slot>
      </div>

      <v-alert
        v-else-if="scope.error"
        class="typeahead__error"
        type="error"
      >
        <slot name="error" v-bind="scope">
          {{ scope.error.message }}
        </slot>
      </v-alert>

      <template v-else-if="scope.data">
        <widget-items-list
          class="typeahead__items"
          v-if="scope.data.length"
          :items="scope.data"
          v-slot="itemData"
          key-field="id"
        >
          <slot name="item" v-bind="{ ...scope, ...itemData }"></slot>
        </widget-items-list>
        <div v-else class="typeahead__screen typeahead__empty">
          <slot name="empty" v-bind="scope">
            <div style="font-size: 24px">😢</div>
            Ничего не найдено
          </slot>
        </div>
      </template>

      <div v-else class="typeahead__screen typeahead__intro">
        <slot name="intro" v-bind="scope">
          <v-icon>fa-keyboard-o</v-icon>
          Введите запрос
        </slot>
      </div>
    </div>
  </widget-finder>
</template>

<script>
import WidgetFinder from '@/components/Widget/WidgetFinder.vue'
import WidgetItemsList from '@/components/Widget/WidgetItemsList.vue'

export default {
  components: {
    WidgetFinder,
    WidgetItemsList,
  },
  props: {
    search: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      query: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.typeahead {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.typeahead__input {
  flex: 00 auto;
}

.typeahead__intro {
}

.typeahead__screen {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>
