<template>
  <widget-card-base
    title="Доктора"
    :url="{ name: 'Doctors' }"
  >
    <widget-typeahead
      :search="search"
      placeholder="Доктор"
    >
      <template #item="{ item }">
        <router-link :to="{ name: 'Doctor', params: { id: item.id } }" target="_blank">
          {{ item.fullName }}
        </router-link>
      </template>
    </widget-typeahead>
  </widget-card-base>
</template>

<script>
import doctorsService from '@/services/doctors'
import WidgetCardBase from '@/components/Widget/WidgetCardBase.vue'
import WidgetTypeahead from '@/components/Widget/WidgetTypeahead.vue'

export default {
  components: {
    WidgetCardBase,
    WidgetTypeahead,
  },
  data () {
    return {
      query: null,
      search: async (search) => {
        if (!search) {
          return null
        }
        const { items } = await doctorsService.getAll({ search })
        return items
      }
    }
  },
}
</script>
