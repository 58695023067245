<script>
import { createRequest } from '@/utils/requestsFactory'
import debounce from 'lodash/debounce'

export default {
  props: {
    search: {
      type: Function,
      required: true
    },
    query: {
      type: String,
      validator: (prop) => (typeof prop === 'string' || prop === null)
    },
    debounce: {
      type: Number,
      default: 500
    }
  },
  data () {
    const req = createRequest(this.search)
    return {
      req,
      input: debounce(function () {
        return req.run(this.query)
      }, this.debounce)
    }
  },
  watch: {
    query () {
      this.input()
    }
  },
  mounted () {
    return this.req.run()
  },
  computed: {
    scope () {
      return {
        data: this.req.data,
        pending: this.req.pending,
        error: this.req.error,
        input: this.input
      }
    }
  },
  render () {
    return this.$scopedSlots.default ? this.$scopedSlots.default(this.scope) : null
  }
}
</script>
